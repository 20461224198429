import React from 'react';
import StyledImage from './StyledImage';
import facebook from '../public/img/facebook.svg';
import { classNames } from '../helpers/classes';

const FacebookLink = () => {
  return (
    <div>
      <a
        href="https://www.facebook.com/JeepOutfitter"
        target="_blank"
        rel="noreferrer"
        className="flex items-center text-white"
      >
        <StyledImage src={facebook} alt="facebook logo" className="flex w-3" />
        <p className="font-normal text-sm mx-2">Facebook</p>
      </a>
    </div>
  );
};

export default FacebookLink;
