import { DEFAULT_LANG, STRING_LANG } from '../helpers/languages';
import { FooterSection } from '../models/footer';
import data from '../public/footer.json';

export const fetchFooter = ({
  lang = DEFAULT_LANG
}: {
  lang: STRING_LANG;
}): FooterSection[] => {
  const { items = [] } = data[lang];
  return items;
};
