import axios from 'axios';
import { ResponseWithError } from '../models/app';

export const newsletterSignup = async ({
  token,
  email,
  lang,
  country
}: {
  token: string;
  email: string;
  lang: string;
  country: string;
}): Promise<ResponseWithError> => {
  const URL = `${process.env.NEXT_PUBLIC_WP_API_URL}/v1/newsletter`;

  try {
    const response = await axios.post(
      URL,
      {
        email
      },
      {
        headers: {
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country,
          ['UYN-BEARER']: token
        }
      }
    );

    if (response instanceof Error) {
      return { error: response };
    }

    if (response.data.error) {
      return { error: response.data };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getNewsletterOffer = async ({
  token,
  lang,
  country
}: {
  token: string;
  lang: string;
  country: string;
}): Promise<ResponseWithError> => {
  const URL = `${process.env.NEXT_PUBLIC_WP_API_URL}/v1/popup-info`;

  try {
    return await axios.get(URL, {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      },
      params: {
        popup: 'newsletter'
      }
    });
  } catch (error) {
    return { error };
  }
};
