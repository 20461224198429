import Link from 'next/link';
import React, { ReactNode } from 'react';

export default function LinkTo({
  href,
  className = '',
  children,
  onClick,
  ...props
}: {
  href: string;
  className?: string;
  children: any;
  onClick?: any;
}) {
  return (
    <Link href={href} prefetch={false}>
      <a className={className} {...props} onClick={onClick}>
        {React.Children.map<ReactNode, ReactNode>(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child);
          }
          return child;
        })}
      </a>
    </Link>
  );
}
