import { useRouter } from 'next/router';
import { getLangCountry } from '../helpers/languages';

const LINK_EXPRESSION =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export default function localizedRoute(href: string) {
  // eslint-disable-next-line unicorn/prefer-regexp-test
  if (href.match(LINK_EXPRESSION)) return href;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { query } = useRouter();
  const { lang, country } = getLangCountry(query);
  const hRef = href.startsWith('/') ? href : `/${href}`;

  return `/${lang}_${country}${hRef}`;
}
