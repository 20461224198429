import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { newsletterSignup } from '../fetchers/newsletter';
import { useAlert } from '../context/AlertProvider';
import { getLangCountry } from '../helpers/languages';
import MiniSpinner from './MiniSpinner';
import { useCookies } from 'react-cookie';

const NewsletterForm = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);
  const alert = useAlert();
  const [cookies] = useCookies(['jeep_bearer']);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const onSubmit = async ({ email }: { email: string }) => {
    setLoading(true);

    const { error } = await newsletterSignup({
      token: cookies['jeep_bearer'],
      email,
      lang,
      country
    });

    setLoading(false);

    if (error) {
      return alert.error(t('GENERIC_ERROR'));
    }

    reset();
    alert.show(t('NEWSLETTER_THANK_YOU'));
  };

  return (
    <div className="mt-6 newsletter-custom-mobile">
      <h3 className="text-gray-400 text-xl mb-3 font-sans font-bold">
        {t('SUBSCRIBE_NEWSLETTER')}
      </h3>
      <form
        className={`flex flex-col ${
          errors.email ? ' border-red-500' : ' border-transparent'
        }`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full flex justify-between items-center flex-col sm:flex-row">
          <input
            type="email"
            placeholder={t('INSERT_YOUR_EMAIL')}
            className="py-2 w-80 bg-black focus:outline-none focus:ring-0 text-white border-none"
            {...register('email', {
              required: t('REQUIRED_FIELD') as string,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                message: t('INVALID_EMAIL')
              }
            })}
          />

          {loading ? (
            <div className="text-primary">
              <MiniSpinner />
            </div>
          ) : (
            <button
              type="submit"
              className="bg-white text-primary px-4 py-1 rounded-md"
            >
              {t('NEWSLETTER_BTN_SIGNUP')}
            </button>
          )}
        </div>
        <div
          className={`relative flex items-center mt-2 py-2 px-3 border rounded ${
            errors.newsletter ? ' border-red-500' : 'border-transparent'
          }`}
        >
          <input
            id="newsletter"
            aria-describedby="privacy-description"
            type="checkbox"
            className="focus:outline-none h-4 w-4 text-primary border-gray-300 rounded"
            {...register('newsletter', {
              required: t('REQUIRED_FIELD') as string
            })}
          />
          <div className="ml-2 text-sm">
            <label htmlFor="newsletter">
              <div
                id="newsletter-description"
                className="text-gray-500 cursor-pointer"
              >
                {t('FOOTER_NEWSLETTER_PRIVACY')}
                <a
                  href={`https://www.iubenda.com/privacy-policy/${t(
                    'IUBENDA.POLICY_ID'
                  )}`}
                  className="iubenda-white no-brand iubenda-noiframe iub-body-embed text-primary"
                  title="Privacy Policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('PRIVACY_TITLE')}
                </a>
              </div>
            </label>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewsletterForm;
